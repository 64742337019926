<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div class="ml-2">
                                        <div class="text-2xl text-primary font-bold">Edit Caps Institution Programmes</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div class="card  nice-shadow-18">
                            <div >
                                <template v-if="!loading">
                                    <div class="grid">
                                        <div class="col-12">
                                            <form ref="observer"  tag="form" @submit.prevent="startRecordUpdate()">
                                                <!--[form-content-start]-->
                                                <div class="grid">
                                                    <div class="col-12">
                                                        Institution *
                                                        <div id="ctrl-caps_institution_id-holder"> 
                                                            <api-data-source @loaded="response => mapOptionField(response, 'caps_institution_id')"  api-path="components_data/caps_institution_id_option_list" >
                                                                <template v-slot="req">
                                                                    <Dropdown class="w-full" :class="getErrorClass('caps_institution_id')"   :loading="req.loading"   optionLabel="label" optionValue="value" ref="ctrlcaps_institution_id"  v-model="formData.caps_institution_id" :options="req.response" label="Institution"  placeholder="Select ..." :filter="true">
                                                                    <template #option="slotProps">
                                                                        <div class="flex align-items-center">
                                                                            <div class="ml-2" v-if="slotProps.option.image">
                                                                                <Avatar :image="$utils.setImgUrl(slotProps.option.image)" />
                                                                                </div>
                                                                                <div>
                                                                                    <div>{{ slotProps.option.label }}</div>
                                                                                    <div v-if="slotProps.option.caption" class="text-sm text-500">{{ slotProps.option.caption }}</div>
                                                                                </div>
                                                                            </div>
                                                                        </template>
                                                                        </Dropdown> 
                                                                    </template>
                                                                </api-data-source>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            Programme *
                                                            <div id="ctrl-caps_programme_id-holder"> 
                                                                <api-data-source @loaded="response => mapOptionField(response, 'caps_programme_id')"  api-path="components_data/caps_programme_id_option_list" >
                                                                    <template v-slot="req">
                                                                        <Dropdown class="w-full" :class="getErrorClass('caps_programme_id')"   :loading="req.loading"   optionLabel="label" optionValue="value" ref="ctrlcaps_programme_id"  v-model="formData.caps_programme_id" :options="req.response" label="Programme"  placeholder="Select ..." :filter="true">
                                                                        <template #option="slotProps">
                                                                            <div class="flex align-items-center">
                                                                                <div class="ml-2" v-if="slotProps.option.image">
                                                                                    <Avatar :image="$utils.setImgUrl(slotProps.option.image)" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <div>{{ slotProps.option.label }}</div>
                                                                                        <div v-if="slotProps.option.caption" class="text-sm text-500">{{ slotProps.option.caption }}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </template>
                                                                            </Dropdown> 
                                                                        </template>
                                                                    </api-data-source>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--[form-content-end]-->
                                                        <div v-if="showSubmitButton" class="text-center my-3">
                                                            <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                                        </div>
                                                    </form>
                                                    <slot :submit="submit" :saving="saving"></slot>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="p-3 text-center">
                                                <ProgressSpinner style="width:50px;height:50px" />
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
</template>
<script>
	import { PageMixin } from "../../mixins/page.js";
	import { EditPageMixin } from "../../mixins/editpage.js";
	import { mapActions, mapGetters, mapState } from "vuex";
	import useVuelidate from '@vuelidate/core';
	import { required, email, sameAs, minLength,maxLength,minValue,maxValue,numeric,integer,decimal,ipAddress } from '@vuelidate/validators';
	export default {
		setup () {
			return { v$: useVuelidate() }
		},
		name: 'editCapsinstitutionprogrammesPage',
		components: {
		},
		mixins: [PageMixin, EditPageMixin ],
		props: {
			pageName: {
				type: String,
				default: 'caps_institution_programmes',
			},
			idName: {
				type: String,
				default: 'caps_institution_programme_id',
			},
			routeName: {
				type: String,
				default: 'caps_institution_programmesedit',
			},
			pagePath: {
				type : String,
				default : 'caps_institution_programmes/edit',
			},
			apiPath: {
				type: String,
				default: 'caps_institution_programmes/edit',
			},
		},
		data() {
            return {
				formData: {
					caps_institution_id: "", caps_programme_id: "", 
				},
				submitted: false,
        	}
		},
		computed: {
			pageTitle:{
				get: function () {
					return "Edit Institution Programme"
				}
			},
			currentRecord: {
				get: function () {
					return this.$store.getters["caps_institution_programmes/currentRecord"];
				},
				set: function (value) {
					this.$store.commit("caps_institution_programmes/setCurrentRecord", value);
				},
			},
		},
		validations() {
			let formData =  {
				caps_institution_id: { required,numeric },caps_programme_id: { required,numeric },
			}
			return {formData}
		},
		methods: {
			...mapActions('caps_institution_programmes', ['updateRecord', 'fetchRecord']),
			async startRecordUpdate(){
				this.submitted = true;
				const isFormValid = !this.v$.$invalid;
				if(!isFormValid){
					this.flashMsg("Please complete the form", "Form is invalid", "error");
					return;
				}
				this.saving = true;
				let id = this.id;
				let url = this.apiUrl;
				let payload = this.normalizedFormData();
				let data = { id, url, payload }
				this.updateRecord(data).then(
					(response) => {
						this.saving = false;
						this.flashMsg(this.msgAfterUpdate);
						this.resetForm();
						this.closeDialogs();// close page dialog that if opened
						if(this.redirect) this.navigateTo(`/caps_institution_programmes`);
					},
					(response) => {
						this.saving = false;
						this.showPageRequestError(response);
					}
				);
			},
			updateFormFields: function(){
				//update form fields value after load from api
				//e.g convert fieldvalue (value,value2,value2) to array 
            },
			resetForm (){
				//reset form fields value
				this.formData = {caps_institution_id: "", caps_programme_id: "", };
				//raise event to reset other custom form components
				//this.$EventBus.$emit("resetForm");
			},
			getErrorClass(field){
			   if(this.v$.formData[field].$invalid && this.submitted){
				    return "p-invalid";
			   }
               return "";
            },
		},
		watch: {
		},
		async mounted() {
		},
		async created() {
		},
	};
</script>
<style scoped>
</style>
